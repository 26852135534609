import consola from 'consola'

export function useMFILogger() {
  const config = useRuntimeConfig()
  const logger = consola.create({
    level: config.public.isDev ? 4 : -1
  })

  return { logger }
}
